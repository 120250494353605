import domReady from '@wordpress/dom-ready';
import $ from 'jquery';

function setScreenSizeCookie() {
    const screenWidth = $(window).width();
    let itemsToShow = 4;

    if (screenWidth < 680) {
        itemsToShow = 1;
    } else if (screenWidth < 1200) {
        itemsToShow = 2;
    } else if (screenWidth < 1900) {
        itemsToShow = 3;
    }

    document.cookie = `itemsToShow=${itemsToShow}; path=/`;
}

domReady(() => {
    // Set the cookie when the document is ready
    setScreenSizeCookie();

    // Reapply the cookie value on window resize
    $(window).resize(function() {
        setScreenSizeCookie();
    });


    function setEqualHeights() {
        const cards = $('.post-item');
        let heights = [];
        cards.each(function () {
            const cardHeight = $(this).outerHeight();
            heights.push(cardHeight);
        });
       
        const newHeight = Math.max(...heights);

        cards.each(function () {
            $(this).css('height', `${newHeight}px`); 
        });
    }

    // Run on document ready
    const sliders = $('.post-slider');
    // sliders.on('init', function() {
    //     setTimeout(function() {
    //         setEqualHeights();
    //     }, 2000); 
    // });


    sliders.slick({
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        autoplay: false,
        autoplaySpeed: 2300, 
        responsive: [
            {
                breakpoint: 1900,
                settings: {
                    slidesToShow: 3,
                },
            },
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 680,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    });

    $(window).resize(function() {
        setTimeout(setEqualHeights, 200);  // Use timeout to ensure Slick adjustments are complete
    });

    // Function to start autoplay
    function startAutoplay(slider) {
        slider.slick('slickSetOption', 'autoplay', true, true);
    }

    // Set up Intersection Observer to detect when the slider is in view
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                // When the slider is in view, start autoplay
                startAutoplay($(entry.target));
                // Optionally, stop observing after autoplay is enabled
                observer.unobserve(entry.target);
            }
        });
    }, {
        threshold: 0.5 // Adjust threshold as needed
    });

    // Observe each slider
    sliders.each((index, slider) => {
        observer.observe(slider);
    });
});
